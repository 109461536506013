export async function setup() {
  const logoData = {};
  //Default English
  logoData["XL"] = require("../assets/mainLogo.png");
  logoData["directline"] = require("../assets/directline.png");
  logoData["kentuni"] = require("../assets/kentuni.png");
  logoData["openBanking"] = require("../assets/openBanking.png");
  logoData["lidl"] = require("../assets/lidl.png");
  logoData["openBankingQR"] = require("../assets/openBankingBTH2.png");
  logoData["schwarz"] = require("../assets/schwarz.png");
  logoData["schwarzWhite"] = require("../assets/schwarzWhite.png");
  logoData["westherts"] = require("../assets/westherts.png");
  return logoData;
}
